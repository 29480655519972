// |------------------------------------------------------
// |------------------------------------------------------
// | Dashboard
// |------------------------------------------------------
// |------------------------------------------------------

.dashboard
	position: relative
	min-height: calc(100vh - 130px)
	.is-size-5
		letter-spacing: -0.03em
	.carousel-list
		width: calc(100% + 30px)
		margin-left: -15px
		.carousel-slide
			border: 0
	&__dashboard
		display: flex
		justify-content: space-between
		align-items: center
		.b-skeleton
			margin: 0
	&__title
		color: $grey-dark
		line-height: 1.1
		text-align: center
		letter-spacing: -0.04em
		@include mobile
			text-align: left
	&__select
		position: relative
		display: inline-block
		margin-bottom: 5px
		.icon
			position: absolute
			top: 12px
			right: 11px
			opacity: 0.4
		&:hover
			.icon
				opacity: 1
		select
			cursor: pointer
			background: $white
			border: 1px solid $grey-lighter
			&:hover
				//background: $grey-lightest
				color: $black
				border-color: $grey-light
			option
				text-align: left
				padding: 0
				font:
					weight: $weight-light
					size: 18px
		select,
		&__inner
			text-align: center
			color: $grey-dark
			border-radius: 5px
			padding: 8px 40px 7px
			appearance: none
			-webkit-appearance: none
			font:
				weight: $weight-semibold
				size: 18px
			transition: $transition-base
	&__box
		border-radius: 5px
		font-size: 3rem
		// max-width: 90%
		margin: 0 auto
		text-align: center
		background: $white
		@include desktop
			max-width: 90%
		&__value
			display: flex
			justify-content: center
			align-items: center
			color: $dark
			font:
				size: 1.3em
				weight: $weight-bold
			letter-spacing: -0.06em
			small
				// color: $grey-light
				font:
					size: 55%
					weight: $weight-light
				position: relative
				top: 4px
				left: -1px
		&__type
			display: block
			margin-top: -20px
			color: $grey-light
			font:
				size: 1.5rem
				weight: $weight-medium
	&__panel
		background: $white
		padding: spacing()
		border-radius: 5px
		// border: 1px solid $grey-lighter
		// border-bottom: 2px solid $grey-lighter
		min-height: calc(100vh - 310px)
		margin-top: -5px
	&__list
		padding: spacing(2) 0
		&__item
			display: flex
			font-size: 1.1em
			padding: 2px 0
			margin: 0  spacing()
			border-bottom: 2px solid $white-ter
			&:last-of-type
				border-bottom: 0
			@include mobile
				padding: spacing(2.5) spacing()
			> span
				position: relative
				top: 1px
				display: flex
				align-items: center
				justify-content: center
			// &:nth-child(even)
				// background: $white-bis
		&__position
			width: 15%
		&__name
			flex-direction: column
			align-items: flex-start!important
			line-height: 1.2
			padding: spacing(3) 0
			width: 70%
			font-size: 1em
			color: $grey-dark
			.is-ellipsis
				display: block
				max-width: 220px
		&__date
			justify-content: flex-end
			width: 30%
			&.logo
				padding: 10px
				border-radius: 5px
				background: #e8e8e8
			img
				width: auto
				height: 15px
				margin: 0
	&__chart
		width: auto
		min-height: 300px
		@include desktop
			height: 370px
	&__error
		height: calc(100% - 90px)!important
	&__results
		display: flex
		justify-content: center
		align-items: center
		text-align: center
	&__results,
	&__error
		svg
			width: 50px!important
			opacity: 0.6
			margin: 0 0 15px 0!important

.big-numbers
	position: relative
	padding-top: 70px
	margin-left: 5px
	.title
		margin: 0
		padding: 0
		top: 20px
		position: absolute
		color: $dark
		font:
			size: 1.6em
			weight: $weight-bold
	.numbers
		display: flex
		justify-content: center
		align-items: center
		text-align: center
		flex-direction: column
		&.bordered
			border-right: 1px dashed $grey-lightest
		h5
			font:
				size: 4em
				weight: $weight-bold
