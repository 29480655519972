// Animations

@keyframes float
  from
    transform: translate(-50%, -50%) translate(0, 100vh)

  to
    transform: translate(-50%, -50%) translate(0, -100vh)

@keyframes shake
  0%
    transform: translate(1px, 1px) rotate(0deg)
  1%
    transform: translate(-1px, -2px) rotate(-1deg)
  2%
    transform: translate(-3px, 0px) rotate(1deg)
  3%
    transform: translate(3px, 2px) rotate(0deg)
  4%
    transform: translate(1px, -1px) rotate(1deg)
  5%
    transform: translate(-1px, 2px) rotate(-1deg)
  6%
    transform: translate(-3px, 1px) rotate(0deg)
  7%
    transform: translate(3px, 1px) rotate(-1deg)
  8%
    transform: translate(-1px, -1px) rotate(1deg)
  9%
    transform: translate(1px, 2px) rotate(0deg)
  10%
    transform: translate(1px, -2px) rotate(-1deg)
  11%
    transform: translate(1px, 1px) rotate(0deg)