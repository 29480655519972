// |------------------------------------------------------
// |------------------------------------------------------
// | Lawyers
// |------------------------------------------------------
// |------------------------------------------------------

.lawyers-page
	.form
		max-width: 960px
		margin: 0 auto
		padding: 0 20px 200px
	.logo
		display: block
		background: $primary
		text-align: center
		padding: 30px
		display: flex
		justify-content: center
		align-items: center
		margin-bottom: 50px
		img
			max-height: 55px
			margin-bottom: -5px
	.head
		max-width: 920px
		margin: 0 auto
		h2
			text-align: center
			margin-bottom: 30px
		p
			color: $grey-dark
			margin-bottom: 15px
		strong
			color: $primary
	.terms
		overflow: scroll
		height: 370px
		background: $white
		padding: 20px
		border-radius: 8px
		margin-bottom: 40px
		p
			margin-bottom: 15px
		h4
			text-transform: uppercase
			font-weight: $weight-bold
			margin: 15px 0 10px
		ol,
		ul
			padding-left: 15px
			margin-bottom: 15px
			li
				margin-bottom: 10px
	.button
		margin-left: auto
		float: right
	.is-size-5
		margin: 55px 0 -10px 0
		padding-bottom: 10px
		border-bottom: 3px solid $primary
