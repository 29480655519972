// |------------------------------------------------------
// |------------------------------------------------------
// | Sidebar
// |------------------------------------------------------
// |------------------------------------------------------

.sidebar
	display: flex
	flex-direction: column
	justify-content: space-around
	align-items: center
	max-width: 90%
	width: 330px
	height: 100vh
	position: fixed
	bottom: 0
	right: 0
	top: 0
	z-index: 9995
	background: $primary
	transition: right 0.6s ease-out
	+ .page
		@include desktop
			width: calc(100% - #{$sidebar-width})
	@include desktop
		//position: sticky
		right: auto
		width: $sidebar-width
	@include touch
		background: $white
		right: -120vw
		justify-content: flex-start
		&.opened
			right: 0
		.tooltip-content
			opacity: 0
	.logo
		margin: 10px 0 0 0
		@include mobile
			img
				height: 30px
				width: auto
	&__head
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%
		height: 65px
		padding: spacing()
		border-bottom: 1px solid rgba($grey-lighter, 0.5)
		.logo
			svg
				fill: $black
				max-width: 80px
		.icon-button
			path
				stroke: $secondary

	&__top
		// position: fixed
		// top: spacing(2)
		margin-top: spacing()
		img
			height: 40px

	&__bottom
		position: fixed
		bottom: spacing(2)
		overflow: hidden
		// padding: spacing(3)
		img
			display: block
			margin: auto
			width: 35px
			border-radius: 100%
			// border: 2px solid $white
			transition: $transition-base
			// box-shadow: 0 0 3px $grey-light
