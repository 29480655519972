// |------------------------------------------------------
// |------------------------------------------------------
// | Blocks
// |------------------------------------------------------
// |------------------------------------------------------

@use 'sass:math'

.block
	display: flex
	align-items: center
	justify-content: flex-start
	position: relative
	padding: spacing(2) spacing(1.5)
	background: $white
	min-height: 90px
	border-radius: 3px
	box-shadow: $shadow
	border: 1px solid transparent
	border-left: 5px solid $grey-light
	transition: $transition-base
	&.full
		padding-top: 0
		padding-bottom: 15px
		flex-wrap: wrap
		@include desktop
			padding-bottom: 0
	&.secondary
		border-left-color: $secondary
	&.inactive
		// background: rgba($white, 0.15)
		border-left-color: $grey-lighter!important
	&.placeholder
		pointer-events: none
	&:hover
		// margin-top: -3px
		opacity: 1
		background: $white
		border-left-color: $primary!important

	.tag
		text-transform: uppercase
		position: absolute
		top: -13px
		right: 10px
		color: $white !important
		font-weight: $weight-bold

	&.no-hover:hover
		border-left-color: $grey-light

	&__content
		padding: 0 spacing(1.5)
		display: flex
		align-items: flex-start
		flex-direction: column
		line-height: 1.2
		width: calc(100% - 50px)
		&.is-align-items-center
			.b-skeleton-item
				margin: 0 auto

	&__label
		text-transform: uppercase
		font-weight: $weight-bold
		font-size: 11px
		text-transform: uppercase
		color: $grey-dark
		margin-bottom: 5px
		span
			font-weight: $weight-regular

	&__value
		text-transform: uppercase
		font-size: 12px
		font-weight: $weight-semibold
		text-align: center
		&.link
			cursor: pointer
			color: $primary
			&:hover
				color: $secondary

	&__role
		display: block
		text-indent: -9999px
		width: 8px
		height: 8px
		background: $grey-light
		border-radius: 100%
		border: 1px solid $white

	&__avatar
		display: flex
		color: $white
		align-items: center
		justify-content: center
		background: $grey-lighter
		border-radius: 100%
		font:
			size: 1.2rem
			weight: $weight-semibold
		.b-tooltip
			position: absolute
			top: 0
			right: 5px
			z-index: 2

		.image
			width: 100%
			background: transparent

		&.cases
			display: flex
			flex-direction: column
			line-height: 1
			color: $grey
			width: 48px
			height: 48px
			background: transparent
			+ .block__content
				border-left: 1px solid $white-ter
				margin-left: 5px
			&:hover
				color: $black
			h4
				margin: 0
				font-weight: $weight-bold
				font-size: 24px
			p, .cases
				margin: 0
				text-transform: uppercase
				font-weight: $weight-semibold
				font-size: 11px

	&__logo
		min-width: 130px
		height: 50px
		display: flex
		justify-content: center
		align-items: center
		padding: 10px
		border-radius: 5px
		background: #e8e8e8
		img
			max-height: 100%
			max-width: 100%
			height: auto

	&__name
		width: 100%
		font-size: 1.2em
		color: $grey
		font-weight: $weight-semibold
		position: relative
		.status-process
			margin: 0
			top: 18px
		a
			color: inherit
			&:hover
				color: $dark
		&.link
			width: auto
			cursor: pointer
			&:hover
				color: $dark
				// text-decoration: underline
		&--user
			padding-top: 0.45rem
		.b-skeleton
			margin-top: 0

	&__email
		text-transform: lowercase

	&__email,
	&__address
		color: $grey-light
		font:
			weight: $weight-light
			size: 0.85em
		&.secondary
			margin-top: 3px

	&__state
		text-transform: uppercase
		font:
			size: 14px
			weight: $weight-light
		color: $grey-lighter
		user-select: none
		padding: spacing(1.5) spacing(1.5) 0 0

	&__status
		margin-right: 25px
		margin-top: 6px
		padding: 0
		svg
			width: 35px

	&__checkbox
		position: absolute
		bottom: -5px
		right: -26px
		opacity: 1!important

	&__dropdown
		position: absolute
		top: 50%
		right: 15px
		margin: -13px 0 0 0

		.dropdown-content
			width: 100%
			border: 1px solid $white-ter
			.svg
				position: relative
				top: -1px
				margin-right: 3px
				svg
					position: relative
					height: auto
					width: 13px
					// margin-right: 3px
				&.profile,
				&.check
					.fill
						fill: $info
				&.check
					svg
						width: 17px
						left: -3px
				&.profile
					svg
						width: 15px
						left: 0
						top: -1px
				&.criteria
					.fill
						fill: $warning
				&.money
					svg
						width: 15px
					.fill
						fill: $success
				&.edit
					svg
						width: 11px
						margin-left: 2px
						margin-right: 2px
				&.trash
					top: -2px
					svg
						width: 16px
						margin-right: 0
				&.restore
					top: 0

		.dropdown-item
			font-weight: $weight-medium
			&.disable
				.restore
					display: inline-block
					transform: rotate(180deg)
					top: 1px

		.dots
			opacity: 0.3
			border: none
			cursor: pointer
			&:hover
				opacity: 0.5

.stats
	@extend .block
	background: $white
	text-align: center
	border: 0
	flex-direction: column
	height: 100%
	justify-content: center
	align-items: center
	padding: 0 5px
	.b-skeleton-item
		text-align: center
		margin: 0 auto
	&__value
		font-weight: $weight-bold
		font-size: 2em
		margin-bottom: -5px
		color: $grey-dark
		span.stats__name
			color: $grey-dark
			font-weight: $weight-semibold
			// @include desktop
				// margin-left: -7px
	&__name
		color: $grey-light
		font-size: 12px
		font-weight: $weight-regular
