// |------------------------------------------------------
// |------------------------------------------------------
// | Navigation
// |------------------------------------------------------
// |------------------------------------------------------

.navigation
	margin: 0
	display: flex
	flex-direction: column
	@include touch
		width: 100%

	&--primary
		margin-top: spacing()
		@include desktop
			margin-top: auto

	&--secondary
		margin-top: auto
		@include touch
			padding-top: spacing()
			padding-bottom: spacing()
			//margin-top: spacing()
			border-top: 1px solid rgba($grey-lighter, 0.5)

	&__link
		display: block
		padding: spacing(2)
		border-radius: 5px
		font-weight: $weight-medium
		transition: $transition-base
		@include desktop
			opacity: 0.5
			margin-bottom: spacing(2)
		@include touch
			padding: spacing(3) spacing()
			font-size: 20px
		.svg,
		.fill
			fill: $white
		.stroke
			stroke: $white
		&:hover
			opacity: 1
			// background: rgb($white, 0.4)
		&.is-active,
		&.router-link-active
			opacity: 1
			background: $white-ter
			font-weight: $weight-semibold
			@include touch
				background: transparent
				color: $primary
			.svg,
			.fill
				fill: $secondary
			.stroke
				stroke: $secondary
		&--logout
			color: $danger
			opacity: 1
			.svg
				opacity: 1
			.fill
				fill: $danger

.icon-button
	display: none
	height: 24px
	width: 24px
	cursor: pointer
	@include touch
		display: block
