// |------------------------------------------------------
// |------------------------------------------------------
// | Page
// |------------------------------------------------------
// |------------------------------------------------------

.page
	padding: $block-spacing
	width: 100%
	min-height: 100vh
	@include desktop
		margin-left: 100px
		padding-left: $block-spacing * 2

	&__button
		background: transparent
		border: none
		cursor: pointer
		position: relative
		padding: 5px 15px 10px
		margin-top: 22px
		height: 40px
		z-index: 5
		color: $primary
		@include touch
			margin-top: 16px
		&--mode
			padding-top: 0
			padding-bottom: 0
			@include touch
				position: fixed
				top: 0
				right: 85px
		.svg,
		.icon
			position: relative
			width: 30px
			opacity: 0.7
			&:hover
				opacity: 1
		.icon
			opacity: 0.6
			top: -4px
		// &__icon
		// 	width: 20px
		// 	height: 20px
		// 	font-size: 8px
		// 	position: absolute
		// 	top: 0
		// 	right: 5px
		// 	background: $danger
		// 	color: $white
		// 	display: flex
		// 	justify-content: center
		// 	align-items: center
		// 	border-radius: 100%

	&__title
		max-width: 100%
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: flex-start

	&__selection
		display: flex
		justify-content: flex-start
		align-items: center
		width: 100%
		max-width: 350px
		position: fixed
		bottom: 10px
		left: 50%
		margin-left: -115px
		padding: 7px
		border-radius: 30px
		background: $white
		z-index: 3
		box-shadow: $shadow
		@include mobile
			margin-left: -175px
		.button
			width: 100%

	&__actions
		display: flex
		align-items: center
		justify-content: flex-end
		@include desktop
			min-height: 70px
		@include touch
			padding-top: 0
			padding-bottom: 0
			justify-content: flex-end
		@include mobile
			justify-content: flex-start
			margin-bottom: $block-spacing
			.icon
				display: none

		.b-skeleton
			height: 40px
			&.is-centered
				position: relative
				top: 5px
				width: 100px
				margin-right: 1.5rem
			&.is-right
				width: 165px
				margin-top: 0
			&-item
				border-radius: 30px!important

		.button
			// line-height: 1.95
			.icon
				position: relative
				top: 2px
			.plus,
			.add-user
				top: -1px
				svg
					width: 21px
			> span
				position: relative
				top: -1px

.back-link
	min-height: 40px
	display: flex
	justify-content: flex-start
	align-items: center
	margin: 26px 0
	a
		display: flex
		color: $grey-dark
		font-weight: $weight-semibold
		text-transform: uppercase
		font-size: 14px
		svg
			margin-right: 5px
			fill: currentColor
			width: 15px
		&:hover
			color: $primary

.lawsuit-content
	width: 100%
	min-height: calc(100vh - 340px)
	background: $white
	padding: spacing()
	box-shadow: $shadow

.lawsuit-tabs
	width: 100%
	&.placeholder
		.tabs
			ul
				li
					a
						width: 140px
						height: 50px
						pointer-events: none
	.tabs
		ul
			border: 0
			li
				a
					color: $grey-light
					border: 0
					padding: 0.8em 1em
					transition: $transition-base
					font-weight: $weight-semibold
				&:hover
					a
						color: $grey-dark
				&.is-active
					a
						background: $white
						color: $grey-dark
	.tab-content
		min-height: calc(100vh - 375px)
		background: $white
		padding: spacing()
		box-shadow: $shadow
		.no-files
			height: 100%
			text-align: center
			font-size: 20px
			font-weight: $weight-semibold
			// margin-top: 10%
			display: flex
			height: 100%
			min-height: 300px
			width: 100%
			flex-direction: column
			justify-content: center
			align-items: center
		.data-title
			text-transform: uppercase
			color: $secondary
			font-weight: $weight-bold
			font-size: 1.5em
			margin: 0.3em 0 1em
			letter-spacing: -0.03em
		.tab-files
			display: flex
			gap: 25px
			flex-wrap: wrap
			// @include desktop
				// flex-wrap: nowrap
			li
				flex: 0 0 100%
				@include tablet
					flex: 0 0 calc(50% - 13px)
				@include fullhd
					flex: 0 0 calc(33.33333333% - 17px)
				a,
				button
					position: relative
					display: flex
					justify-content: center
					align-items: center
					text-align: center
					width: 100%
					height: 60px
					border-radius: 6px
					background: $white-ter
					padding: 15px
					color: $grey-light
					transition: $transition-base
					.type
						background: $grey-dark
						color: $white
						text-transform: uppercase
						border-radius: 4px
						padding: 0 5px
						font-size: 12px
						position: absolute
						top: -6px
						right: 5px
						font-weight: $weight-bold
						&.pdf,
						&.ppt,
						&.pptx
							background: #e77f67
						&.xls,
						&.xlsx,
						&.csv
							background: #78e08f
						&.doc,
						&.docs,
						&.word
							background: #82ccdd
						&.svg,
						&.png,
						&.gif,
						&.jpg,
						&.jpeg
							background: #fad390
						&.mov,
						&.mp4,
						&.mp3,
						&.ogv
							background: #f8a5c2
					&:hover
						color: $white
						background: $primary
				button
					cursor: pointer
					border: 2px dashed $grey-lightest
					color: $grey-lightest
					background: $white
					text-transform: uppercase
					font-size: 18px
					font-weight: $weight-bold
					&:hover
						border-color: $grey-light
						background: $grey-light
		.data-list
			margin-bottom: 1em
			flex-wrap: wrap
			li
				margin: 0 5% 20px 0
				min-width: 150px
				&.full
					width: 100%
					&:first-of-type
						margin-bottom: 2em
				&:first-of-type
					min-width: 30%
			h6
				text-transform: uppercase
				font-size: 12px
				font-weight: $weight-bold
				color: $grey-dark
			p
				color: $grey-light
				letter-spacing: -0.02em
				font-weight: $weight-semibold
				font-size: 1.35em
				margin-bottom: -8px
		.data-action
			background: $grey-lightest
			border-radius: 10px
			padding: 1.25em
			text-align: center
			margin-bottom: 1.25em
			margin-left: auto
			@include tablet
				max-width: 350px
			&.placeholder
				background: $white-ter
			.b-skeleton-item
				text-align: center
				margin: 0 auto
			p,
			a
				font-size: 1.4em
				font-weight: $weight-bold
				color: $grey-dark
				margin-top: -10px
			a
				// text-decoration: underline
				&:hover
					color: $primary
			h6
				text-transform: uppercase
				margin-bottom: 10px
				font-weight: $weight-medium
				color: $grey
			.select,
			select
				width: 100%
				text-align: center
				font-size: 13px
				min-height: 40px

.comments
	width: 85%
	max-width: 700px
	margin-left: auto
	margin-right: auto
	display: flex
	flex-direction: column
	padding: 32px 0 32px 32px
	border-left: 2px solid $white-ter
	font-size: 1.125rem
	position: relative
	.comments-item
		display: flex
		gap: 24px
		&:first-child:before,
		&:last-child:before
			content: ''
			width: 4px
			height: 30px
			background: $white
			position: absolute
		&:first-child:before
			top: 0
			left: -3px
		&:last-child:before
			bottom: 0
			left: -3px
		& + *
			margin-top: 24px
		& + .extra-space
			margin-top: 48px

	.timeline-item-icon
		display: flex
		align-items: center
		justify-content: center
		width: 40px
		height: 40px
		border-radius: 50%
		margin-left: -52px
		flex-shrink: 0
		overflow: hidden
		box-shadow: 0 0 0 6px #fff
		svg
			width: 20px
			height: 20px
			fill: $white
		&.faded-icon
			background-color: #a55eea
		&.timeline-comment
			background: $primary
		&.timeline-upload
			background: $secondary
		&.timeline-created
			background: $success

	.new-comment
		position: relative
		width: 100%
		.button
			position: absolute
			bottom: -10px
			right: 20px
			opacity: 0
			// transition: $transition-base
		input,
		textarea
			border: 1px solid $grey-light
			border-radius: 6px
			min-height: 48px
			padding: 1em
			width: 100%
			height: 95px
			font-size: 16px
			color: $grey-light
			&::placeholder
				color: $grey-light
			&:focus
				color: $grey
				border-color: $primary
				outline: 0
				box-shadow: 0 0 0 3px $primary
				+ .button
					opacity: 1

	.timeline-item-description
		display: flex
		padding-top: 9px
		font-size: 16px
		color: $grey
		img
			flex-shrink: 0
		a
			color: $grey
			font-weight: 500
			text-decoration: none
			&:hover,
			&:focus
				outline: 0
				color: $primary

	.avatar
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%
		overflow: hidden
		aspect-ratio: 1 / 1
		flex-shrink: 0
		width: 40px
		height: 40px
		&.small
			width: 28px
			height: 28px
		img
			object-fit: cover

	.comment
		margin-top: 12px
		color: $grey
		border: 1px solid $grey-lightest
		border-radius: 6px
		padding: 1em
		font-size: 0.875rem

.lawyer-cases
	display: flex
	flex-direction: column
	li
		display: block
		margin-bottom: 5px
		a
			display: block
			padding: 10px 20px
			color: $primary
			background: $white-ter
			border-radius: 5px
			font-weight: $weight-medium
			&:hover
				color: $white
				background: $primary
