// |------------------------------------------------------
// |------------------------------------------------------
// | Modal
// |------------------------------------------------------
// |------------------------------------------------------

.modal
	z-index: 9998
	.modal-content,
	.modal-card
		border-radius: $radius-large
		max-height: none
		// @include from($desktop)
		// 	max-height: none
	.modal-card-head
		padding: 30px 20px
	.modal-card-title
		display: flex
		justify-content: space-between
		color: $primary
	.modal-card
		width: 100%
		max-width: 100%
		padding: 5px
		margin: 0 -5px
	.modal-card-body
		position: relative
		max-height: calc(100vh - 210px)
		@include touch
			max-height: none
		// margin: 0 0 -15px 0
		margin: 0
	.modal-card-foot
		justify-content: space-between
		z-index: 10
		.button
			padding: 7px 40px
			width: auto
			height: auto
	.modal-updated
		user-select: none
		display: flex
		justify-content: flex-end
		color: $grey-light
		margin: 0
		font-size: 70%
	&.is-avatar
		.modal-content
			width: 350px

	&.is-sm,
	&.is-users,
	&.is-types,
	&.is-category,
	&.is-attributes,
	&.is-people,
	&.is-lawyer,
	&.is-cases
		.modal-content
			width: 450px

	&.is-md,
	&.is-products,
	&.is-companies,
	&.is-categories
		.modal-content
			width: 640px

	&.is-lg,
	&.is-lawyers
		.modal-content
			width: 960px

	&.is-delete
		.modal-card
			max-width: 460px

		.modal-card-head
			background: $danger
			text-align: center
			padding: 1.3em

		.modal-card-title
			display: block
			color: white
			text-align: center

		.modal-card-foot
			justify-content: center
			.button
				margin: 0 15px
				border-radius: 2em
				&:focus
					outline: none
					box-shadow: none
				&:not(.is-primary)
					border-color: $danger
					color: $danger
					background: transparent
					&:hover
						background: $danger
						color: $white

		.media-content
			padding: 1em
			text-align: center
			span
				font-size: 1.4rem
				color: $grey-dark
				line-height: 1
			small
				display: block
				margin-top: 10px
				font-size: 75%
				color: $grey-light
				line-height: 1.4
				&.destroy
					margin: 0
					display: flex
					input
						position: relative
						top: 1px
						margin-right: 5px

	&.is-roles
		.role
			text-transform: uppercase
		.action
			user-select: none
			transition: $transition-base
			cursor: pointer
			&.selected,
			&:hover
				color: $primary
		.item
			margin: 0
			@include touch
				.column
					// padding-top: 0
					padding-right: 0
				.is-2-tablet
					padding-left: 0
			&:last-child
				// margin-bottom: $block-spacing * 6
				hr
					display: none

	.modal-content
		@include touch
			width: 90%!important

.modal-rounded
	border-radius: 0 0 5px 5px
.modal-select
	//border-radius: 0 0 5px 5px
	min-height: 250px
	ul
		margin-bottom: 20px
	&__name
		display: block
		margin-bottom: 10px
		color: $grey-dark
		font:
			size: 0.8em
			weight: $weight-semibold
	&__item
		font-weight: $weight-light
		width: 100%
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 5px
		font-size: 20px
		color: $grey-light
		padding: spacing(2) spacing(1.5)
		border-radius: 5px
		line-height: 1
		&.category
			// flex-direction: column
			// align-items: flex-start
			background: lighten($white-ter, 2%)
		.field
			margin: 0px 0 -5px 0
		.control-label
			font-size: 16px
			max-width: 290px
			@extend .is-ellipsis
		.b-checkbox.checkbox input + .check
			box-shadow: none!important
		i
			position: relative
			top: 2px
			color: $grey-light
		&.done
			font-weight: $weight-regular
			color: $black
			background: transparent
			i
				color: $black
		&.disabled
			opacity: 0.5
			pointer-events: none
			user-select: none
	&__item.checkbox:hover,
	&__item.checked,
	a:hover
		background: $secondary
		color: $white
		.b-checkbox.checkbox input + .check
			border-color: $white
		i,
		.checkbox,
		.control-label
			color: inherit

.modal-groups
	&__item
		display: flex
		justify-content: flex-start
		border: 4px solid $white
		transition: $transition-base
		background: $white-bis
		border-radius: 15px
		@include mobile
			flex-wrap: wrap
		@include tablet
			justify-content: space-between
		&.active
			background: $secondary
			color: $white
		&.done
			background: $success
		&.error
			background: $danger
			//background: #ffbbbb
			color: $white
		&.contested
			background: $warning
			color: $white
		.switch
			margin-left: 15px
			margin-right: -5px
		span,
		.field
			display: flex
			align-items: center
		// .adjust-width-contest
		// 	input
		// 		width: 70px
		&.header
			background: transparent
			border: none
			margin: 0 0 -40px 0
			padding-top: 0
			padding-bottom: 0
			font-size: 12px
			text:
				transform: uppercase
				align: center
	&__group
		position: relative
		color: $grey-dark
		padding-left: 10px
		margin-top: spacing()
		user-select: none
		font:
			size: 16px
			weight: $weight-semibold
		&.first
			margin-top: 0
		.switch
			position: absolute
			top: 2px
			right: 0
			margin: 0 -40px 0 0
			z-index: 15
			input
				box-shadow: none!important
		span
			position: relative
			z-index: 5
			background: $white
			padding-right: spacing()
		&::after
			content: ''
			display: block
			width: 95%
			height: 2px
			background: $white-ter
			position: absolute
			top: 12px
			right: 0
			z-index: 1
	&__name
		width: 100%
		max-width: 100%
		transition: $transition-base
		font:
			size: 0.9em
			weight: $weight-regular
		// pointer-events: none
		user-select: none
		margin-bottom: 10px
		line-height: 1
		@include tablet
			width: 200px
			margin-bottom: 0
			padding-left: spacing(2)
		@include desktop
			width: 370px
		&.small
			@include desktop
				width: 200px
		&.large
			@include tablet
				width: auto
				font:
					size: 1.3em
		.header &
			font-size: inherit
		span
			max-width: 100%
	&__points
		justify-content: center
		color: $white
		background: $grey-light
		border-radius: 2px
		padding: 5px 15px
		width: 55px
		user-select: none
		pointer-events: none
		text-transform: uppercase
		font:
			size: 20px
			weight: $weight-semibold
		@include tablet
			margin-left: auto
		&.voted
			width: 65px
		.active &
			background: $white
			color: $secondary
		.error &
			background: $white
			color: $danger
		// .contested &
		// 	color: $warning
		.header &
			justify-content: center
			font:
				size: inherit
				weight: $weight-light
			color: $grey-light
			background: transparent
	&__rate
		width: 70px
		margin: 0 10px
		input
			padding-left: 10px
			padding-right: 10px!important
			text-align: center
			height: 43px
			width: 5rem
			letter-spacing: -0.05em
			text-transform: uppercase
			font-size: 15px!important
		.icon,
		.help
			display: none!important
		.header &
			justify-content: center
	&__notes
		min-width: 250px
		&.comment
			@include mobile
				width: calc(100% - 128px)
				margin-right: 0 !important
		&.contest
			@include mobile
				margin-top: 10px
				width: 100%

		input
			// min-height: 50px
			font-size: 14px
			height: 41px
		.header &
			font-size: inherit

.modal-payment
	background: $white-ter
	border: 2px solid lighten($grey-light, 20%)
	border-radius: 15px
	padding: 15px 25px 5px
	overflow: hidden
	position: relative
	&__title
		font:
			size: 20px
			weight: $weight-semibold
		color: $dark
	&__field
		padding-top: 10px
		max-width: 620px
		.tag
			position: relative
			top: -2px
	&__icon
		fill: $grey-lighter
		position: absolute
		top: -20px
		right: -20px
		width: 200px
		height: auto
		transform: rotate(45deg)
		opacity: 0.1
		pointer-events: none
		svg
			width: 100%

.modal-rules
	background: $white-ter
	border-radius: $radius
	padding: 15px
	display: flex
	flex-wrap: wrap
	align-items: flex-start
	justify-content: space-between
	flex-flow: row wrap
	row-gap: 10px
	margin-bottom: 150px
	overflow-y: auto
	max-height: 240px
	&:after
		content: ''
		flex: auto
	&__item
		display: flex
		justify-content: flex-start
		align-items: center
		flex: 0 0 33.33333%
		font-size: 16px
		// background: red
		margin: 0 0 5px 0
		&.double
			flex: 0 0 50%
		input
			text:
				transform: uppercase
				align: center
				width: 65px
				padding-right: 10px!important
				appearance: textfield
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				appearance: none
		.icon,
		.help
			display: none!important
	&__index
		display: flex
		justify-content: center
		align-items: center
		background: $primary
		color: $white
		font-size: 16px
		width: 40px
		height: 30px
		user-select: none
		border-radius: $radius
		input
			background: transparent
			color: inherit
			border: none
			padding: 0!important
			min-width: 30px
			text-align: center
			border-radius: 4px
			&:focus
				background: $secondary
	&__signal
		padding: 0 10px
		color: $grey-dark
	&__help
		width: 100%
		display: block

.list-laywers
	min-height: 400px

.button-laywer
	display: flex
	flex-direction: column
	align-items: flex-start
	border: 0
	background: transparent
	color: $dark
	font-size: 18px
	margin-bottom: 20px
	padding: 13px 15px
	font-weight: $weight-semibold
	margin-bottom: 10px
	background: $white-bis
	border-radius: 10px
	transition: none
	position: relative
	&:hover
		color: $white
		background: $primary
		svg
			path
				fill: $white
		.button
			color: $white
			svg
				fill: $white
	.link
		cursor: pointer
		transition: $transition-base
		&:hover
			padding-left: 5px
	.button
		color: $grey
		border: 0
		text-transform: uppercase
		margin: 10px 15px 0 auto
		font-size: 12px
		background: transparent
		transition: $transition-base
		&:hover
			margin-right: 10px
		svg
			position: relative
			top: -2px
			margin-left: 10px
			width: 18px
			fill: $grey
	.arrow
		position: absolute
		top: 15px
		right: 15px
		padding: 0
		margin: 0
		border: 0
		background: transparent
		margin-left: auto
		background: rgba($white, 0.2)
		border-radius: 5px
		cursor: pointer
		width: 30px
		transition: none
		svg
			position: relative
			top: 1px
			width: 18px
		&.opened
			.svg
				transform: rotate(180deg)
		&:hover
			background: white
			svg
				path
					fill: $primary
	.details
		padding: 15px 1px
		// &.opened
			// display: block
		.column
			margin-top: 10px
			padding-bottom: 0
			padding-top: 0
		p
			font-size: 12px
			font-weight: $weight-regular
			small
				display: block
				text-transform: uppercase
				font-weight: $weight-bold
				// opacity: 0.7


.domain
	user-select: none
	background: rgb(219, 219, 219)
	// height: 50px
	padding: 2px 10px
	border-radius: 5px
	display: flex
	justify-content: center
	align-items: center
	color: $white
	position: absolute
	top: 29px
	right: 20px
	word-wrap: break-word
	max-width: 100%
	white-space: normal
	font-size: 16px
	&.filled
		background: $success

.logo.upload
	width: 100%
	.upload-draggable
		min-height: 70px
		width: 100%
		padding: 20px
		text-align: center
		color: $grey-light
		font-weight: $weight-semibold

.theme-color
	.vue-swatches
		margin-top: 0
		.vue-swatches__trigger
			width: 80px !important
			height: 70px !important
			margin-right: 7px !important
